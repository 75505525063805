//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	data(){
		return {
			dataarr:[
				{
					title1:'001',
					title2:require('../../assets/image/zhaosi.jpg'),
					title3:'张三',
					title4:'13012345678',
					title5:'2022-01-21',
				},
				{
					title1:'001',
					title2:require('../../assets/image/zhaosi.jpg'),
					title3:'张三',
					title4:'13012345678',
					title5:'2022-01-21',
				},
				{
					title1:'001',
					title2:require('../../assets/image/zhaosi.jpg'),
					title3:'张三',
					title4:'13012345678',
					title5:'2022-01-21',
				},
				{
					title1:'001',
					title2:require('../../assets/image/zhaosi.jpg'),
					title3:'张三',
					title4:'13012345678',
					title5:'2022-01-21',
				},
				{
					title1:'001',
					title2:require('../../assets/image/zhaosi.jpg'),
					title3:'张三',
					title4:'13012345678',
					title5:'2022-01-21',
				},
			],
			currentPage: 1,
		}
	},
	methods:{
		add(){
			this.$router.push('add-employee')
		},
		edit(){
			this.$router.push('edit-employee')
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},
	}
}
